import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import {
	ArrayField,
	ChipField,
	Datagrid,
	DateField,
	Filter,
	FunctionField,
	NumberField,
	ReferenceArrayField,
	ReferenceArrayInput,
	ReferenceField,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
	SingleFieldList,
	TextField,
	useListContext,
	useListFilterContext,
	usePermissions,
	useRecordContext,
	useRedirect,
} from 'react-admin';
import { ArrayChipInput, CheckChainUsesModule, CustomizableDatagrid, ModuleKey } from '../../components';
import { BetweenDatesTimesInput } from '../../components/BetweenDatesInput';
import { CPFField } from '../../components/Fields';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getAttendantId, getChainId, getEmployeeType, getPlaceId, getUserId } from '../../lib';
import { EmployeeType, FillinOrigin, TagType } from '../../models';

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
}));

const discountChoices = [
	{ id: 'withDiscount', name: 'Com Desconto' },
	{ id: 'withoutDiscount', name: 'Sem Desconto' }
];

const groupByChoices = [
	{ id: 'fuel_id', name: "Combustível" },
	{ id: "attendant_id", name: "Frentista" },
	{ id: "place_id", name: "Posto" },
]

const label = {
	place_id: "Posto",
	attendant_id: "Frentista",
	fuel_id: "Combustível",
}

const reference = {
	place_id: "stations",
	attendant_id: "attendants",
	fuel_id: "fuels",
}

const arraySource = {
	place_id: "places",
	fuel_id: "fuels",
	attendant_id: "attendants",
}

const arrayLabel = {
	place_id: "Postos",
	fuel_id: "Combustíveis",
	attendant_id: "Frentistas",
}

const CustomerField = () => {
	const { permissions } = usePermissions();
	const record = useRecordContext();

	return record && record.customer_id
		?
		(<ReferenceField
			label="Cliente"
			emptyText="Não identificado"
			source="customer_id"
			basePath="customers"
			reference="customers"
			link={permissions?.includes(PERMISSIONS.LIST_CUSTOMER) && "show"}
		>
			<TextField source="name" />
		</ReferenceField>)
		:
		(<CPFField source="customer_cpf" label="CPF" emptyText="Não identificado" record={record} />);
}

const SummaryFields = [
	<NumberField
		source="total_value"
		label="Total em Dinheiro"
		textAlign="center"
		emptyText="R$ 0,00"
		options={{
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}}
		sortable={false}
	/>,
	<NumberField
		source="total_amount"
		label="Total em Litros"
		textAlign="center"
		emptyText="0"
		options={{
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}}
		sortable={false}
	/>,
	<TextField source="total_fillins" textAlign="center" label="Total de abastecimentos" sortable={false} />,
	<NumberField
		source="average_ticket"
		label="Ticket Médio"
		textAlign="center"
		emptyText="R$ 0,00"
		options={{
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}}
		sortable={false}
	/>,
	<NumberField
		source="average_amount"
		label="Volume Médio"
		textAlign="center"
		emptyText="0"
		options={{
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}}
		sortable={false}
	/>,
	<NumberField
		source="discount"
		label="Total de descontos"
		textAlign="center"
		emptyText="R$ 0,00"
		options={{
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}}
		sortable={false}
	/>
]

const GenericArrayField = (fillinGroup, arraySource, arrayLabel, label, reference, SummaryFields, index) => {
	if (index === 0) {
		return [
			<FunctionField
				label={label[fillinGroup[index]]}
				render={(record) => record && record.id !== "0" ?
					<ReferenceField
						source={fillinGroup[index]}
						reference={reference[fillinGroup[index]]}
						textAlign="center"
						emptyText="Não identificado"
						link={false}
						sortable={false}
					>
						<TextField source="name" />
					</ReferenceField>
					: <TextField source="name" emptyText="Todos" />
				}
			/>,
			GenericArrayField(fillinGroup, arraySource, arrayLabel, label, reference, SummaryFields, index + 1),
		]
	} else if (fillinGroup && fillinGroup.length > index) {
		return (
			<ArrayField source={arraySource[fillinGroup[index - 1]]} label={arrayLabel[fillinGroup[index]]} sortable={false} >
				<Datagrid bulkActionButtons={false} >
					<FunctionField
						label={label[fillinGroup[index]]}
						render={(record) => record && record.id !== "0" ?
							<ReferenceField
								source={fillinGroup[index]}
								reference={reference[fillinGroup[index]]}
								textAlign="center"
								emptyText="Não identificado"
								link={false}
								sortable={false}
							>
								<TextField source="name" />
							</ReferenceField>
							: <TextField source="name" emptyText="Todos" />
						}
					/>
					{
						GenericArrayField(fillinGroup, arraySource, arrayLabel, label, reference, SummaryFields, index + 1)
					}
				</Datagrid>
			</ ArrayField>
		)
	} else {
		return SummaryFields;
	}
}

const FillinsList = () => {
	const classes = useStyles();
	const props = useListContext();
	const { permissions } = usePermissions();
	const redirect = useRedirect();
	const { filterValues } = useListFilterContext();

	const fillinGroup = filterValues?.groupBy;
	const index = fillinGroup ? 0 : null;

	let data = [];
	let summaryData = [];

	const sumSummaryData = (acc, value) => {
		if (Array.isArray(value)) {
			return value.reduce((a, b) => sumSummaryData(a, b), acc);
		} else if (value[value.type]) {
			return sumSummaryData(acc, value[value.type]);
		} else {
			acc.total_value += Number(value.total_value);
			acc.total_fillins += Number(value.total_fillins);
			acc.total_amount += Number(value.total_amount);
			acc.discount += Number(value.discount);
			return acc;
		}
	};


	if (props.data) {
		const fillinInfo = props.data[0];

		if (fillinInfo) {
			data = fillinInfo.data;
			if (Array.isArray(fillinInfo.summaryData)) {
				summaryData = fillinInfo.summaryData;
				if (summaryData.length > 1) {
					const totalSummary = summaryData.reduce((acc, curr) => {
						acc = sumSummaryData(acc, curr);
						return acc
					}, { id: "0", total_value: 0, total_fillins: 0, total_amount: 0, discount: 0 });

					totalSummary.average_amount = totalSummary.total_amount / totalSummary.total_fillins;
					totalSummary.average_ticket = totalSummary.total_value / totalSummary.total_fillins;

					if (fillinGroup && fillinGroup.length > 1) {
						totalSummary[arraySource[fillinGroup[0]]] = [Object.assign({}, totalSummary)];
						if (fillinGroup.length > 2) {
							const secondGroup = arraySource[fillinGroup[1]];
							if (!totalSummary[arraySource[fillinGroup[0]]][secondGroup]) {
								const { [arraySource[fillinGroup[0]]]: firstGroup, ...keys } = totalSummary;
								totalSummary[arraySource[fillinGroup[0]]] = [{ id: "0", [secondGroup]: [{ ...Object.assign({}, keys, { id: "0" }) }] }];
							}
						}
					}
					summaryData = [totalSummary, ...summaryData];
				}
			} else {
				summaryData = [fillinInfo.summaryData];
			}
		}
	}

	return (
		<>
			<Typography className={classes.title}>Total</Typography>
			<Datagrid
				bulkActionButtons={false}
				style={{ marginBottom: 60 }}
				data={summaryData}
			>
				{
					GenericArrayField(fillinGroup, arraySource, arrayLabel, label, reference, SummaryFields, index)
				}
			</Datagrid>

			<Typography className={classes.title}>Abastecimento</Typography>
			<CustomizableDatagrid
				resource={props.resource}
				data={data}
				rowClick={(id) => redirect('show', 'fillins', id)}
				bulkActionButtons={false}
				defaultColumns={['place_id', 'chain_id', 'value', 'amount', 'price_per_liter', 'old_price_per_liter', 'discount', 'discount_id', 'fuel_id', 'created_at']}
			>
				<ReferenceField
					label="Posto"
					emptyText="Posto não identificado"
					source="place_id"
					reference="places"
					link={permissions?.includes(PERMISSIONS.LIST_STATION) && "show"}
				>
					<TextField source="name" />
				</ReferenceField>
				<Permission label="Frentista" permission={PERMISSIONS.LIST_ATTENDANT}>
					<ReferenceField
						label="Frentista"
						emptyText="Não identificado"
						source="attendant_id"
						basePath="attendants"
						reference="attendants"
						link="show"
					>
						<TextField source="name" />
					</ReferenceField>
				</Permission>
				<CustomerField label="Cliente" />
				<NumberField
					source="value"
					label="Valor"
					textAlign="center"
					emptyText="R$ 0,000"
					options={{
						style: 'currency',
						currency: 'BRL',
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					}}
				/>
				<NumberField
					source="amount"
					label="Litros"
					textAlign="center"
					emptyText="0"
					options={{
						maximumFractionDigits: 2
					}}
				/>
				<NumberField
					source="hose_number"
					label="Bico"
					textAlign="center"
					emptyText="Não informado"
				/>
				<NumberField
					source="encerrante"
					label="Encerrante"
					textAlign="center"
					emptyText="Não informado"
					options={{
						maximumFractionDigits: 2
					}}
				/>
				<NumberField
					source="price_per_liter"
					label="Preço com desconto"
					textAlign="center"
					emptyText="R$ 0,000"
					options={{
						style: 'currency',
						currency: 'BRL',
						minimumFractionDigits: 3,
						maximumFractionDigits: 3
					}}
				/>
				<NumberField
					source="old_price_per_liter"
					label="Preço normal"
					textAlign="center"
					emptyText="R$ 0,000"
					options={{
						style: 'currency',
						currency: 'BRL',
						minimumFractionDigits: 3,
						maximumFractionDigits: 3
					}}
				/>
				<NumberField
					source="discount"
					label="Desconto"
					textAlign="center"
					emptyText="R$ 0,000"
					options={{
						style: 'currency',
						currency: 'BRL',
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					}}
				/>
				<ReferenceField source="discount_id" reference="discounts" label="Código do desconto" textAlign="center" emptyText="--">
					<TextField source="identify" />
				</ReferenceField>
				<ReferenceField label="Combustível" emptyText="Não identificado" source="fuel_id" basePath="fuels" reference="fuels" link={false}>
					<TextField source="name" />
				</ReferenceField>
				<FunctionField
					source="origin"
					label="Origem"
					render={record => FillinOrigin.translateOrigin(record ? record.origin : null)}
				/>
				<DateField source="created_at" label="Data Concentrador" showTime />
				<DateField source="received_at" label="Recebido em" showTime />
			</CustomizableDatagrid>
		</>
	);
}

const fillinsExporter = async (data, fetchRelatedRecords) => {
	let fillinInfo = data[0].data;
	const relations = [
		{ field: 'attendant_id', resource: 'attendants' },
		{ field: 'fuel_id', resource: 'fuels' },
		{ field: 'place_id', resource: 'stations' },
		{ field: 'customer_id', resource: 'customers' },
	];

	const fetchData = await fetchRelatedRecords(relations, fillinInfo);

	const getData = (resource) => {
		return fetchData.find(({ resource: res }) => res === resource)?.results || [];
	}

	fillinInfo = fillinInfo.map(fillin => {
		const { attendant_id, fuel_id, place_id, customer_id, ...fillinRest } = fillin;
		const attendant = getData('attendants').find(({ id }) => id === attendant_id);
		const fuel = getData('fuels').find(({ id }) => id === fuel_id);
		const place = getData('stations').find(({ id }) => id === place_id);
		const customer = getData('customers').find(({ id }) => id === customer_id);

		return {
			...fillinRest,
			attendant_name: attendant ? attendant?.name : 'Não identificado',
			fuel_name: fuel ? fuel?.name : 'Não identificado',
			place_name: place ? place?.name : 'Não identificado',
			customer_name: customer ? customer?.name : 'Não identificado',
		}
	});

	return fillinInfo;
}

const HoseNumberChip = (props) => {
	const record = useRecordContext();
	if (!record) { return null; }
	return (
		<ChipField record={{ hoseNumber: record }} source="hoseNumber" />
	);
}

const ListFilters = (props) => {
	const { permissions } = usePermissions();
	const placeId = getPlaceId();
	const { filterValues } = useListContext();

	return (
		<Filter {...props}>
			<BetweenDatesTimesInput alwaysOn />
			{(permissions && permissions.includes(PERMISSIONS.ADMIN)) &&
				<ReferenceInput
					source="chainId"
					reference="chains"
					perPage={null}
					sort={{ field: "name", order: "ASC" }}
					alwaysOn
				>
					<SelectInput
						emptyText="Todos"
						label="Rede"
						style={{ minWidth: 180 }}
						optionText="name"
					/>
				</ReferenceInput>
			}
			{!placeId &&
				<ReferenceArrayInput
					source="stationIds"
					reference="stations"
					perPage={null}
					filter={getEmployeeType() === EmployeeType.attendant ? { attendantId: getAttendantId() } : {}}
					sort={{ field: "name", order: "ASC" }}
					alwaysOn
				>
					<SelectArrayInput
						label="Posto"
						emptyText="Todos"
						style={{ minWidth: 180 }}
						optionText="name"
						disabled={filterValues.tagIds}
					/>
				</ReferenceArrayInput>
			}
			<ReferenceArrayInput
				label="Produto"
				source="fuelIds"
				reference="fuels"
				sort={{ field: "name", order: "ASC" }}
			>
				<SelectArrayInput
					emptyText="Todos"
					style={{ minWidth: 180 }}
					label="Produto"
					optionText="name"
				/>
			</ReferenceArrayInput>
			<SelectInput label="Desconto" choices={discountChoices} emptyText={"Todos"} source="discount" />
			<SelectArrayInput
				label="Origem"
				source="origin"
				choices={FillinOrigin.fillinOriginChoices}
				emptyText="Todos"
				style={{ minWidth: 180 }}
			/>
			<SelectArrayInput
				label="Agrupar por"
				source="groupBy"
				choices={groupByChoices}
				style={{ minWidth: 180 }}
			/>
			{(permissions && permissions.includes(PERMISSIONS.CUSTOMER_GROUPS)) &&
				<ReferenceArrayInput
					label="Grupo de clientes"
					source="customerGroupIds"
					reference="chains/chainId/customer-groups"
					sort={{ field: "name", order: "ASC" }}
					perPage={null}
				>
					<SelectArrayInput
						label="Grupo de clientes"
						emptyText="Todos"
						style={{ minWidth: 180 }}
						optionText="name"
					/>
				</ReferenceArrayInput>
			}
			{(getEmployeeType() === EmployeeType.admin || [EmployeeType.chain, EmployeeType.place].includes(getEmployeeType())) &&
				CheckChainUsesModule({
					module: ModuleKey.PROMOTER, children: (
						<ReferenceArrayInput
							source="promoterIds"
							reference="promoters"
							sort={{ field: "name", order: "ASC" }}
							filter={filterValues.chainId ? { chainId: filterValues.chainId } : {}}
							emptyText="Todos"
						>
							<SelectArrayInput
								style={{ minWidth: 180 }}
								helperText={false}
								optionText="name"
								label="Promoter"
							/>
						</ReferenceArrayInput>
					)
				})
			}
			<ArrayChipInput source="hoseNumbers" label="Bicos (Pressione Enter)" />
			{(!placeId && (permissions && permissions.includes(PERMISSIONS.TAG))) &&
				<ReferenceArrayInput
					source="tagIds"
					label='Etiqueta'
					reference="chains/chainId/tag"
					sort={{ field: 'name', order: "ASC" }}
					perPage={null}
					filter={{ type: TagType.station, chainId: filterValues.chainId }}
				>
					<SelectArrayInput
						label='Etiqueta'
						style={{ minWidth: 180 }}
						optionText="name"
						disabled={filterValues.stationIds}
					/>
				</ReferenceArrayInput>
			}
		</Filter>
	);
}

const FilterTablePrint = () => {
	const classes = useStyles();
	const { filterValues } = useListContext();
	return (
		<Datagrid data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1} bulkActionButtons={false}>
			<DateField source="from" textAlign="center" label="De" sortable={false} />
			<DateField source="to" textAlign="center" label="Até" sortable={false} />
			<ReferenceArrayField label="Posto" source="stationIds" textAlign="center" basePath="stations" reference="stations" link={false} >
				<Datagrid bulkActionButtons={false}>
					<TextField source="name" label={"Postos"} />
				</Datagrid>
			</ReferenceArrayField>
			<ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" basePath="fuels" reference="fuels" link={false}>
				<TextField source="name" />
			</ReferenceField>
			<Permission permission={PERMISSIONS.CUSTOMER_GROUPS}>
				<ReferenceArrayField label="Grupo de clientes" emptyText="Todos" source="customerGroupIds" textAlign="center" reference="chains/chainId/customer-groups" link={false}>
					<SingleFieldList>
						<ChipField source="name" />
					</SingleFieldList>
				</ReferenceArrayField>
			</Permission>
			<ArrayField label="Bicos" emptyText="Todos" source="hoseNumbers" textAlign="center">
				<SingleFieldList>
					<HoseNumberChip />
				</SingleFieldList>
			</ArrayField>
			<Permission permission={PERMISSIONS.TAG}>
				<ReferenceArrayField label="Etiquetas" emptyText="Todas" source="tagIds" textAlign="center" reference='chains/chainId/tag'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>
			</Permission>
			<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
		</Datagrid>
	);
}

const ReportFillins = (props) => {
	const { permissions } = usePermissions();
	const placeId = getPlaceId();

	const filterDefaultValues = {
		chainId: getChainId(),
		from: moment().startOf('day').toISOString(),
		to: moment().endOf('day').toISOString(),
	};

	if (placeId) {
		filterDefaultValues.stationIds = [placeId];
	}

	if (getEmployeeType() === EmployeeType.promoter) {
		filterDefaultValues.promoterIds = [getUserId()];
	}

	if (getEmployeeType() === EmployeeType.attendant) {
		filterDefaultValues.attendantIds = [getAttendantId()];
	}

	return (
		<Permission permission={PERMISSIONS.FILLINS_REPORT}>
			<ReportList
				{...props}
				basePath="fillins"
				title="Abastecimento"
				resource="chains/chainId/reports/fillins"
				filters={<ListFilters />}
				sort={{ field: 'created_at', order: 'DESC' }}
				filterDefaultValues={filterDefaultValues}
				bulkActionButtons={false}
				customExporter={fillinsExporter}
				titleOnPrint="Relatório de abastecimento"
				filterTablePrint={<FilterTablePrint />}
				showFilterButton
				landscape
			>
				<FillinsList />
			</ReportList>
		</Permission>
	);
};

export default ReportFillins;